import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';

const ShopOwnerDashboard = ({ shopName, shopLocation }) => {
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [itemPromotion, setItemPromotion] = useState('');
  const [items, setItems] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const validateFields = () => {
    if (!itemName || !itemDescription || !itemPrice || !itemQuantity) {
      setErrorMessage("Oops! Enter all key info. Only the promotion field can be empty.");
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const addItem = async () => {
    if (!validateFields()) return;
    try {
      await addDoc(collection(db, 'items'), {
        name: itemName,
        description: itemDescription,
        price: itemPrice,
        quantity: itemQuantity,
        promotion: itemPromotion,
        shop: shopName,
        location: shopLocation,
      });
      setItemName('');
      setItemDescription('');
      setItemPrice('');
      setItemQuantity('');
      setItemPromotion('');
      fetchItems();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const updateItem = async (id) => {
    if (!validateFields()) return;
    const itemRef = doc(db, 'items', id);
    await updateDoc(itemRef, {
      name: itemName,
      description: itemDescription,
      price: itemPrice,
      quantity: itemQuantity,
      promotion: itemPromotion,
    });
    setEditingItemId(null);
    fetchItems();
  };

  const deleteItem = async (id) => {
    await deleteDoc(doc(db, 'items', id));
    fetchItems();
  };

  const fetchItems = async () => {
    const q = query(collection(db, 'items'), where('shop', '==', shopName), where('location', '==', shopLocation));
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() });
    });
    setItems(results);
  };

  const startEditing = (item) => {
    setEditingItemId(item.id);
    setItemName(item.name);
    setItemDescription(item.description);
    setItemPrice(item.price);
    setItemQuantity(item.quantity);
    setItemPromotion(item.promotion);
  };

  const cancelEditing = () => {
    setEditingItemId(null);
    setItemName('');
    setItemDescription('');
    setItemPrice('');
    setItemQuantity('');
    setItemPromotion('');
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,5}(\.\d{0,2})?$/;
    if (regex.test(value)) {
      setItemPrice(value);
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,5}(\.\d{0,2})?$/;
    if (regex.test(value)) {
      setItemQuantity(value);
    }
  };

  useEffect(() => {
    if (shopName && shopLocation) {
      fetchItems();
    }
  }, [shopName, shopLocation]);

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h2 className="dashboard-title">Your Shop Dashboard</h2>
      </header>
      <div className="item-inputs">
        <input
          type="text"
          placeholder="Item Name"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Item Description"
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
        />
        <input
          type="text"
          placeholder="Item Price"
          value={itemPrice}
          onChange={handlePriceChange}
          pattern="^\d{0,5}(\.\d{0,2})?$"
        />
        <input
          type="text"
          placeholder="Item Quantity"
          value={itemQuantity}
          onChange={handleQuantityChange}
          pattern="^\d{0,5}(\.\d{0,2})?$"
        />
        <input
          type="text"
          placeholder="Promotion/Discount"
          value={itemPromotion}
          onChange={(e) => setItemPromotion(e.target.value)}
        />
        {editingItemId ? (
          <>
            <button onClick={() => updateItem(editingItemId)}>Save</button>
            <button onClick={cancelEditing}>Cancel</button>
          </>
        ) : (
          <button onClick={addItem}>Add Item</button>
        )}
      </div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <h3>Product Inventory:</h3>
      <div className="inventory">
        {items.map((item, index) => (
          <div key={item.id} className="inventory-item">
            <span>{index + 1}. {item.name}</span>
            <span>{item.description}</span>
            <span>${item.price}</span>
            <span>{item.quantity}</span>
            <span>{item.promotion}</span>
            <div className="item-buttons">
              <button className="edit-button" onClick={() => startEditing(item)}>Edit</button>
              <button className="delete-button" onClick={() => deleteItem(item.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopOwnerDashboard;
