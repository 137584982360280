import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../App.css';

const AllItems = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items'));
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push(doc.data());
      });
      setItems(results);
    };

    fetchItems();
  }, []);

  return (
    <div className="items">
      <h2>All Items</h2>
      <div className="item-list">
        {items.map((item, index) => (
          <div key={index} className="item-horizontal">
            <span>Shop: {item.shop}</span>
            <span>Location: {item.location}</span>
            <span>Name: {item.name}</span>
            <span>Description: {item.description}</span>
            <span>Quantity: {item.quantity}</span>
            <span>Price: ${item.price}</span>
            <span>Promotion: {item.promotion}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllItems;
