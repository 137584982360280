import React from 'react';
import '../App.css';

const Saved = ({ savedShops, savedItems, onShopClick, onItemClick, onDeleteShop, onDeleteItem }) => {
  return (
    <div className="saved-page">
      <h1 className="saved-title">Saved Shops and Items</h1>
      <div className="saved-section">
        <h2>Saved Shops</h2>
        {savedShops.map((shop, index) => (
          <div key={index} className="saved-item">
            <button onClick={() => onShopClick(shop)}>
              {shop}
            </button>
            <button onClick={() => onDeleteShop(shop)} className="delete-button">Delete</button>
          </div>
        ))}
      </div>
      <div className="saved-section">
        <h2>Saved Items</h2>
        {savedItems.map((item, index) => (
          <div key={index} className="saved-item">
            <button onClick={() => onItemClick(item)}>
              {item}
            </button>
            <button onClick={() => onDeleteItem(item)} className="delete-button">Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Saved;
