import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../App.css';

const Promotions = () => {
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const fetchPromotions = async () => {
      const q = query(collection(db, 'items'), where('promotion', '!=', ''));
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push(doc.data());
      });
      setPromotions(results);
    };

    fetchPromotions();
  }, []);

  return (
    <div className="promotions">
      <h2>All Promotions</h2>
      <div className="promotion-list">
        {promotions.map((promo, index) => (
          <div key={index} className="promotion-item-horizontal">
            <span>Shop: {promo.shop}</span>
            <span>Location: {promo.location}</span>
            <span>Item: {promo.name}</span>
            <span>Quantity: {promo.quantity}</span>
            <span>Price: ${promo.price}</span>
            <span>Promotion: {promo.promotion}</span>
            <span>Description: {promo.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Promotions;
