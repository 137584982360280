import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../App.css';

const AllShops = () => {
  const [shops, setShops] = useState([]);

  useEffect(() => {
    const fetchShops = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const results = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.shopName && data.shopLocation) {
          results.push({
            name: data.shopName,
            location: data.shopLocation,
          });
        }
      });
      setShops(results);
    };

    fetchShops();
  }, []);

  return (
    <div className="shops">
      <h2>All Shops</h2>
      <div className="shop-list">
        {shops.map((shop, index) => (
          <div key={index} className="shop-item-horizontal">
            <span>Name: {shop.name}</span>
            <span>Location: {shop.location}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllShops;
