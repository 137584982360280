import React, { useState, useEffect } from 'react';
import Register from './components/Register';
import Login from './components/Login';
import ShopOwnerRegister from './components/ShopOwnerRegister';
import ShopOwnerLogin from './components/ShopOwnerLogin';
import ShopOwnerDashboard from './components/ShopOwnerDashboard';
import Saved from './components/Saved';
import Promotions from './components/Promotions';
import AllShops from './components/AllShops';
import AllItems from './components/AllItems';
import { auth, db } from './firebaseConfig';
import { collection, query, where, getDocs, getDoc, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css';

const App = () => {
  const [view, setView] = useState('home');
  const [searchResults, setSearchResults] = useState([]);
  const [shopSearch, setShopSearch] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shopName, setShopName] = useState('');
  const [shopLocation, setShopLocation] = useState('');
  const [shopNotFound, setShopNotFound] = useState(false);
  const [itemNotFound, setItemNotFound] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [savedShops, setSavedShops] = useState([]);
  const [savedItems, setSavedItems] = useState([]);

  const fetchSavedData = async (uid) => {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      setSavedShops(data.savedShops || []);
      setSavedItems(data.savedItems || []);
    }
  };

  const searchShops = async (shopName) => {
    if (shopName.trim() === '') {
      setShopNotFound(false);
      return;
    }
    const q = query(collection(db, 'items'), where('shop', '==', shopName));
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push(doc.data());
    });
    setSearchResults(results);
    setShopNotFound(results.length === 0);
  };

  const saveShop = async () => {
    if (shopSearch.trim() !== '' && !savedShops.includes(shopSearch)) {
      const newSavedShops = [...savedShops, shopSearch];
      setSavedShops(newSavedShops);
      if (isLoggedIn) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, {
          savedShops: arrayUnion(shopSearch)
        });
      }
    }
  };

  const deleteSavedShop = async (shop) => {
    const newSavedShops = savedShops.filter(savedShop => savedShop !== shop);
    setSavedShops(newSavedShops);
    if (isLoggedIn) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        savedShops: arrayRemove(shop)
      });
    }
  };

  const searchItems = async (itemName) => {
    if (itemName.trim() === '') {
      setItemNotFound(false);
      return;
    }
    const q = query(collection(db, 'items'), where('name', '==', itemName));
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push(doc.data());
    });
    setSearchResults(results);
    setItemNotFound(results.length === 0);
  };

  const saveItem = async () => {
    if (itemSearch.trim() !== '' && !savedItems.includes(itemSearch)) {
      const newSavedItems = [...savedItems, itemSearch];
      setSavedItems(newSavedItems);
      if (isLoggedIn) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, {
          savedItems: arrayUnion(itemSearch)
        });
      }
    }
  };

  const deleteSavedItem = async (item) => {
    const newSavedItems = savedItems.filter(savedItem => savedItem !== item);
    setSavedItems(newSavedItems);
    if (isLoggedIn) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        savedItems: arrayRemove(item)
      });
    }
  };

  useEffect(() => {
    if (shopSearch) {
      searchShops(shopSearch);
    }
  }, [shopSearch]);

  useEffect(() => {
    if (itemSearch) {
      searchItems(itemSearch);
    }
  }, [itemSearch]);

  const handleShopLoginSuccess = (shopName, shopLocation) => {
    setShopName(shopName);
    setShopLocation(shopLocation);
    setView('shop-dashboard');
    setIsLoggedIn(true);
    setUserRole('shop-owner');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setView('home');
    setShopName('');
    setShopLocation('');
    setUserRole('');
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const { shopName, shopLocation, role } = userDoc.data();
        if (role === 'shop-owner') {
          handleShopLoginSuccess(shopName, shopLocation);
        } else {
          setIsLoggedIn(true);
          setView('home');
          setUserRole(role);
          fetchSavedData(user.uid);
        }
      } else {
        setIsLoggedIn(false);
        setView('home');
        setUserRole('');
      }
    });
  }, []);

  const renderContent = () => {
    switch (view) {
      case 'register':
        return (
          <div className="form-container">
            <Register />
            <button className="text-button" onClick={() => setView('shop-registration')}>Shop Registration</button>
            <button className="back-button" onClick={() => setView('home')}>Back</button>
          </div>
        );
      case 'login':
        return (
          <div className="form-container">
            <Login />
            <button className="back-button" onClick={() => setView('home')}>Back</button>
          </div>
        );
      case 'shop-registration':
        return (
          <div className="form-container">
            <ShopOwnerRegister />
            <button className="back-button" onClick={() => setView('home')}>Back</button>
          </div>
        );
      case 'shop-login':
        return (
          <div className="form-container">
            <ShopOwnerLogin onLoginSuccess={handleShopLoginSuccess} />
            <button className="back-button" onClick={() => setView('home')}>Back</button>
          </div>
        );
      case 'shop-dashboard':
        return <ShopOwnerDashboard shopName={shopName} shopLocation={shopLocation} />;
      case 'saved':
        return (
          <Saved 
            savedShops={savedShops} 
            savedItems={savedItems} 
            onShopClick={(shop) => { setShopSearch(shop); setView('home'); }} 
            onItemClick={(item) => { setItemSearch(item); setView('home'); }} 
            onDeleteShop={deleteSavedShop}
            onDeleteItem={deleteSavedItem}
          />
        );
      case 'promotions':
        return <Promotions />;
      case 'allShops':
        return <AllShops />;
      case 'allItems':
        return <AllItems />;
      default:
        return (
          <div className="main-content">
            <div className="search-bar-wrapper">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Find a shop to browse all their items"
                  value={shopSearch}
                  onChange={(e) => setShopSearch(e.target.value)}
                />
                {isLoggedIn && userRole !== 'shop-owner' && (
                  <button className="save-shop-button" onClick={saveShop}>Save Shop</button>
                )}
              </div>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search for an item across all shops"
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                />
                {isLoggedIn && userRole !== 'shop-owner' && (
                  <button className="save-item-button" onClick={saveItem}>Save Item</button>
                )}
              </div>
            </div>
            <div className="search-results">
              <div className="search-result-header">
                <span>Shop</span>
                <span>Location</span>
                <span>Item</span>
                <span>Quantity</span>
                <span>Price</span>
                <span>Promotion</span>
                <span>Description</span>
              </div>
              {shopNotFound && shopSearch.trim() !== '' && <p>Shop not found</p>}
              {itemNotFound && itemSearch.trim() !== '' && <p>Item not found</p>}
              {searchResults.map((result, index) => (
                <div key={index} className="search-result-item">
                  <span>{result.shop}</span>
                  <span>{result.location}</span>
                  <span>{result.name}</span>
                  <span>{result.quantity}</span>
                  <span>${result.price}</span>
                  <span>{result.promotion}</span>
                  <span>{result.description}</span>
                </div>
              ))}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="App">
      <header>
        <h1 className="logo" onClick={() => setView('home')}>OneShop</h1>
        <div className="browse-section">
          <button onClick={() => setView('allShops')}>Browse All Shops</button>
          <button onClick={() => setView('allItems')}>Browse All Items</button>
          <button onClick={() => setView('promotions')}>Browse All Promotions</button>
        </div>
        {view === 'shop-dashboard' && (
          <>
            <span className="shop-name">{shopName}</span>
            <span className="shop-location">{shopLocation}</span>
            <button className="logout-button" onClick={handleLogout}>Log Out</button>
          </>
        )}
        {view !== 'shop-dashboard' && (
          <nav>
            {isLoggedIn ? (
              view === 'saved' ? (
                <button className="logout-button" onClick={handleLogout}>Log Out</button>
              ) : (
                userRole === 'shop-owner' ? (
                  <button className="dashboard-button" onClick={() => setView('shop-dashboard')}>Dashboard</button>
                ) : (
                  <button className="saved-button" onClick={() => setView('saved')}>Saved</button>
                )
              )
            ) : (
              <>
                <button onClick={() => setView('login')}>Login</button>
                <button onClick={() => setView('register')}>Register</button>
              </>
            )}
          </nav>
        )}
      </header>
      {renderContent()}
    </div>
  );
};

export default App;
